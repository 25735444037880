import { OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { APP_DATE_FORMATS, DateValidator, NumPositiveValidator } from 'app/core/helpers';
import { PlannerCase, PlannerModuleType, GenerateModuleLicenseCommand } from '@appmodels';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
var ɵ0 = APP_DATE_FORMATS;
var GenerateLicenseDialogComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {MatDialogRef<GenerateLicenseDialogComponent>} matDialogRef
     * @param _data
     */
    function GenerateLicenseDialogComponent(
    // private _casesService: CasesService,
    matDialogRef, _data, _cdr, logger) {
        var _this = this;
        this.matDialogRef = matDialogRef;
        this._data = _data;
        this._cdr = _cdr;
        this.logger = logger;
        // medicalReferalsEnum = MedicalReferals;
        // operationTypeEnum = OperationType;
        this.moduleTypeEnum = PlannerModuleType;
        this.validateUser = function (control) {
            if (!control || !control.parent) {
                return null;
            }
            var userEmail = control.parent.get('owner').value;
            var isValid = false;
            if (!_this.foundUsers || _this.foundUsers.length == 0) {
                isValid = false;
            }
            else {
                var foundUsersIndex = _this.foundUsers.findIndex(function (user) { return user.email == userEmail; });
                if (foundUsersIndex >= 0)
                    isValid = true;
            }
            return isValid ? null : { wrongUserEmail: true };
        };
        // Set the defaults
        this.generateLicenseForm = this.createGenerateLicenseForm();
        // this.showExtraToFields = false;
        this._unsubscribeAll = new Subject();
        this.onSearchQueryChanged = new EventEmitter();
    }
    Object.defineProperty(GenerateLicenseDialogComponent.prototype, "users", {
        set: function (value) {
            this.foundUsers = value;
            if (!this._cdr['destroyed']) {
                this._cdr.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    GenerateLicenseDialogComponent.prototype.createGenerateLicenseForm = function () {
        var generateLicenseForm = new FormGroup({
            owner: new FormControl({
                value: '',
                disabled: true
            }),
            requestUid: new FormControl('', Validators.required),
            moduleType: new FormControl('', Validators.required),
            startDate: new FormControl(new Date(), [Validators.required, DateValidator.dateVaidator]),
            days: new FormControl(0, [Validators.required, NumPositiveValidator.numPositiveValidator]),
        });
        return generateLicenseForm;
    };
    GenerateLicenseDialogComponent.prototype.generateLicense = function () {
        if (this.generateLicenseForm.invalid)
            return;
        var generateLicenseCommand = new GenerateModuleLicenseCommand();
        generateLicenseCommand.RequestUID = this.generateLicenseForm.get('requestUid').value;
        generateLicenseCommand.ModuleType = this.generateLicenseForm.get('moduleType').value;
        generateLicenseCommand.StartDate = this.generateLicenseForm.get('startDate').value;
        generateLicenseCommand.Days = this.generateLicenseForm.get('days').value;
        this.matDialogRef.close([generateLicenseCommand]);
    };
    GenerateLicenseDialogComponent.prototype.onSearchQuery = function (searchQuery) {
        if (!searchQuery || searchQuery.length < 3)
            return;
        this.onSearchQueryChanged.emit(searchQuery);
    };
    GenerateLicenseDialogComponent.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    return GenerateLicenseDialogComponent;
}());
export { GenerateLicenseDialogComponent };
export { ɵ0 };
