<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Generate license</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>



        <form name="composeForm" [formGroup]="generateLicenseForm" class="generate-license-form" fxLayout="column" fxFlex>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Owner</mat-label>
                <input matInput name="owner" formControlName="owner" type="email">
            </mat-form-field> -->


            <mat-form-field appearance="outline">
                <mat-label>User</mat-label>
                <input type="text" placeholder="Search user..." aria-label="User email" matInput formControlName="owner"
                    #owner (input)="onSearchQuery(owner.value)" >
                    <!-- [matAutocomplete]="auto" -->
                <!-- <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let user of foundUsers" [value]="user.email">
                        {{user.email}}
                    </mat-option>
                </mat-autocomplete> -->

                <!-- <mat-error *ngIf="caseEditForm.get('owner').hasError('required')">
                    User is required
                </mat-error>
                <mat-error
                    *ngIf="!caseEditForm.get('owner').hasError('required') && caseEditForm.get('owner').hasError('wrongUserEmail')">
                    Wrong user email
                </mat-error> -->
            </mat-form-field>



            <mat-form-field appearance="outline">
                <mat-label>User request</mat-label>
                <input matInput name="requestUid" formControlName="requestUid" type="text">
            </mat-form-field>
      

            <mat-form-field appearance="outline">
                <mat-label>Module</mat-label>
                <mat-select name="type" formControlName="moduleType">
                    <mat-option [value]="moduleTypeEnum.Modules2D">
                        Modules2D
                    </mat-option>                  
                </mat-select>
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>Start date</mat-label>
                <input matInput name="startDate" formControlName="startDate" [matDatepicker]="startDatePicker"
                    placeholder="choose a start date">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>

            
            <mat-form-field appearance="outline">
                <mat-label>Days</mat-label>
                <input matInput name="days" formControlName="days" type="number">
            </mat-form-field>

        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>

            <button mat-raised-button color="accent" (click)="generateLicense()"
                class="save-button" [disabled]="generateLicenseForm.invalid || requestInProgress" aria-label="Create">
                Generate
            </button>

            <!-- <button [fxShow]="isEditingCase" mat-raised-button color="accent" (click)="editCase()" class="save-button"
                [disabled]="generateLicenseForm.invalid || requestInProgress" aria-label="Create">
                Edit
            </button> -->

            <span class="p-4 red-500-fg">
                {{errorText}}
            </span>

            <!-- <button mat-icon-button matTooltip="Attach a file">
                <mat-icon>attach_file</mat-icon>
            </button> -->
        </div>

        <button mat-icon-button (click)="matDialogRef.close(['delete',generateLicenseForm])" aria-label="Delete"
            matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>