import { PlannerUsersAvailableModules } from "@appmodels";

export interface IPaidModulesState
{
    usersPaidModules: PlannerUsersAvailableModules[];   
    searchPattern: string;
}

export const initialPaidModulesState: IPaidModulesState =
{
    usersPaidModules: null,
    searchPattern:''
}