import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { Observable, of, forkJoin } from "rxjs";
import { filter, take } from "rxjs/operators";
import { GetUsersPaidModules } from "../store/actions/paidModules.actions";
import { ResetUsersFilter } from "../store/actions/users.actions";
import { selectUsersPaidModules } from "../store/selectors/paidModules.selector";
import { selectUsers } from "../store/selectors/users.selectors";
import { IAppState } from "../store/state/app.state";

@Injectable({ providedIn: 'root' })
export class PaidModulesResolver {
    constructor(private _store: Store<IAppState>, private logger: NGXLogger) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const routeParams = route.params;

        this.logger.info('Paid modules route resolver: ', route.params)

        const obsCollection: Observable<any>[] = [];

        obsCollection.push(this._store.select(selectUsersPaidModules).pipe(
            filter(allPaidModules => allPaidModules != null),
            take(1)
        ));

        obsCollection.push(of(this._store.dispatch(new GetUsersPaidModules())));

        return forkJoin(obsCollection);
    }
}