/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i5 from "../../../../../@fuse/services/config.service";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/router";
import * as i8 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "./main.component";
import * as i13 from "@angular/material/dialog";
var styles_ContactsMainSidebarComponent = [i0.styles];
var RenderType_ContactsMainSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsMainSidebarComponent, data: {} });
export { RenderType_ContactsMainSidebarComponent as RenderType_ContactsMainSidebarComponent };
export function View_ContactsMainSidebarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "sidebar-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "header p-24"], ["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "avatar mr-16"], ["src", "/assets/images/avatars/profile.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Modules"])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "content py-16"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 4407296, null, 0, i4.FusePerfectScrollbarDirective, [i1.ElementRef, i5.FuseConfigService, i6.Platform, i7.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["aria-label", "Generate license"], ["class", "mat-accent  compose-dialog-button w-80-p"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generateLicenseDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" GENERATE LICENSE "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "p-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(16, 0, null, null, 10, "div", [["class", "content py-16"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 4407296, null, 0, i4.FusePerfectScrollbarDirective, [i1.ElementRef, i5.FuseConfigService, i6.Platform, i7.Router], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "mat-subheading-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["- Modules filter -"])), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "nav material2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 3, "div", [["class", "export-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "button", [["aria-label", "Export users paid modules list"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportUsersPaidModulesList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(25, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" EXPORT "]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 4, 0, currVal_1); var currVal_2 = ""; _ck(_v, 9, 0, currVal_2); var currVal_5 = ""; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = (i1.ɵnov(_v, 25).disabled || null); var currVal_7 = (i1.ɵnov(_v, 25)._animationMode === "NoopAnimations"); _ck(_v, 24, 0, currVal_6, currVal_7); }); }
export function View_ContactsMainSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "contacts-main-sidebar", [], null, null, null, View_ContactsMainSidebarComponent_0, RenderType_ContactsMainSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i12.ContactsMainSidebarComponent, [i13.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsMainSidebarComponentNgFactory = i1.ɵccf("contacts-main-sidebar", i12.ContactsMainSidebarComponent, View_ContactsMainSidebarComponent_Host_0, {}, { onUsersFilterChanged: "onUsersFilterChanged", onGenerateLicense: "onGenerateLicense", onExportUsersClicked: "onExportUsersClicked" }, []);
export { ContactsMainSidebarComponentNgFactory as ContactsMainSidebarComponentNgFactory };
