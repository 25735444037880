import { GenerateModuleLicenseCommand, PlannerUsersAvailableModules } from "@appmodels";
import { Action } from "@ngrx/store";

export enum EPaidModulesActions {
    SetPaidModulesSearchPattern = '[PaidModules] Set Paid Modules Search Pattern',
    SetPaidModulesSearchPatternSuccess = '[PaidModules] Set Paid Modules Search Pattern Success',
    GetUsersPaidModules = '[PaidModules] Get Users Paid Modules',
    GetUsersPaidModulesSuccess = '[PaidModules] Get Users Paid Modules Success',
    AddUsersPaidModules = '[PaidModules] Add Users Paid Modules',
    AddUsersPaidModulesSuccess = '[PaidModules] Add Users Paid Modules Success',
    EditUsersPaidModules = '[PaidModules] Edit Users Paid Modules',
    EditUsersPaidModulesSuccess = '[PaidModules] Edit Users Paid Modules Success',
    RemoveUsersPaidModules = '[PaidModules] Remove Users Paid Modules',
    RemoveUsersPaidModulesSuccess = '[PaidModules] Remove Users Paid Modules Success',    
    GenerateLicense = '[PaidModules] Generate License',    
}

export class SetPaidModulesSearchPattern implements Action {
    public readonly type = EPaidModulesActions.SetPaidModulesSearchPattern;
    constructor(public payload: string) { };
}

export class SetPaidModulesSearchPatternSuccess implements Action {
    public readonly type = EPaidModulesActions.SetPaidModulesSearchPatternSuccess;
    constructor(public payload: string) { };
}

export class GetUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.GetUsersPaidModules;
    constructor() { };
}

export class GetUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.GetUsersPaidModulesSuccess;
    constructor(public payload: PlannerUsersAvailableModules[]) { };
}

export class AddUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.AddUsersPaidModules;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class AddUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.AddUsersPaidModulesSuccess;
    constructor() { };
}

export class EditUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.EditUsersPaidModules;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class EditUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.EditUsersPaidModulesSuccess;
    constructor() { };
}

export class RemoveUsersPaidModules implements Action {
    public readonly type = EPaidModulesActions.RemoveUsersPaidModules;
    constructor(public payload: PlannerUsersAvailableModules) { };
}

export class RemoveUsersPaidModulesSuccess implements Action {
    public readonly type = EPaidModulesActions.RemoveUsersPaidModulesSuccess;
    constructor() { };
}

export class GenerateLicense implements Action {
    public readonly type = EPaidModulesActions.GenerateLicense;
    constructor(public payload: GenerateModuleLicenseCommand) { };
}

export type PaidModulesActions = SetPaidModulesSearchPattern | SetPaidModulesSearchPatternSuccess | GetUsersPaidModules | GetUsersPaidModulesSuccess | AddUsersPaidModules | AddUsersPaidModulesSuccess
|EditUsersPaidModules | EditUsersPaidModulesSuccess | RemoveUsersPaidModules | RemoveUsersPaidModulesSuccess;