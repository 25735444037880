import { Component, Inject, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy, Input, Output, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS, DateValidator, NumPositiveValidator, ObjectMapper } from 'app/core/helpers';
import { OperationType, SceneType, Gender, MedicalReferals, CreatePlannerCaseCommand, PlannerCase, PlannerCaseRelatedData, ICaseSerializedDataObject, ICaseOrder, PlannerModuleType, GenerateModuleLicenseCommand } from '@appmodels';
import { CasesService } from '@appservices';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { PlannerUser } from 'app/core/models/planner/plannerUser';

@Component({
    selector: 'generate-license',
    templateUrl: './generate-license.component.html',
    styleUrls: ['./generate-license.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class GenerateLicenseDialogComponent implements OnDestroy {

    @Input()
    set users(value: PlannerUser[]) {
        this.foundUsers = value;
        if (!this._cdr['destroyed']) {
            this._cdr.detectChanges();
        }
    }

    foundUsers: PlannerUser[];

    @Output()
    onSearchQueryChanged: EventEmitter<string>;

    // medicalReferalsEnum = MedicalReferals;
    // operationTypeEnum = OperationType;
    moduleTypeEnum = PlannerModuleType;


    // showExtraToFields: boolean;
    generateLicenseForm: FormGroup;
    errorText: string;
    requestInProgress: boolean;

    editedCase: PlannerCase;
    isEditingCase: boolean;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param {MatDialogRef<GenerateLicenseDialogComponent>} matDialogRef
     * @param _data
     */
    constructor(
        // private _casesService: CasesService,
        public matDialogRef: MatDialogRef<GenerateLicenseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _cdr: ChangeDetectorRef,
        private logger: NGXLogger
    ) {

        // Set the defaults
        this.generateLicenseForm = this.createGenerateLicenseForm();
        // this.showExtraToFields = false;
        this._unsubscribeAll = new Subject();

        this.onSearchQueryChanged = new EventEmitter();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create case edit form
     *
     * @returns {FormGroup}
     */
    createGenerateLicenseForm(): FormGroup {

        var generateLicenseForm = new FormGroup({

            owner: new FormControl({
                value: '',
                disabled: true
            }),

            requestUid: new FormControl('', Validators.required),

            moduleType: new FormControl('', Validators.required),

            startDate: new FormControl(new Date(), [Validators.required, DateValidator.dateVaidator]),

            days: new FormControl(0, [Validators.required, NumPositiveValidator.numPositiveValidator]),
        });


        return generateLicenseForm;
    }

    validateUser: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control || !control.parent) {
            return null;
        }

        const userEmail = control.parent.get('owner').value;

        let isValid = false;

        if (!this.foundUsers || this.foundUsers.length == 0) {
            isValid = false;
        }
        else {

            const foundUsersIndex = this.foundUsers.findIndex(user => user.email == userEmail);

            if (foundUsersIndex >= 0)
                isValid = true;
        }

        return isValid ? null : { wrongUserEmail: true };
    }

    generateLicense() {

        if (this.generateLicenseForm.invalid)
            return;

        var generateLicenseCommand = new GenerateModuleLicenseCommand();
        generateLicenseCommand.RequestUID = this.generateLicenseForm.get('requestUid').value;
        generateLicenseCommand.ModuleType = this.generateLicenseForm.get('moduleType').value;
        generateLicenseCommand.StartDate = this.generateLicenseForm.get('startDate').value;
        generateLicenseCommand.Days = this.generateLicenseForm.get('days').value;

        this.matDialogRef.close([generateLicenseCommand]);
    }


    onSearchQuery(searchQuery) {

        if (!searchQuery || searchQuery.length < 3)
            return;

        this.onSearchQueryChanged.emit(searchQuery);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
