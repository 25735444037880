import { EPaidModulesActions, PaidModulesActions } from "../actions/paidModules.actions";
import { initialPaidModulesState, IPaidModulesState } from "../state/paidModules.state";

export function paidModulesReducers(
    state = initialPaidModulesState,
    action: PaidModulesActions
): IPaidModulesState {
    switch (action.type) {
        case EPaidModulesActions.SetPaidModulesSearchPatternSuccess:
            {
                const searchPattern = action.payload;
                return {
                    ...state,
                    searchPattern: searchPattern
                }
            }
        case EPaidModulesActions.GetUsersPaidModulesSuccess:
            {
                const usersPaidModules = action.payload;
                return {
                    ...state,
                    usersPaidModules: usersPaidModules
                }
            }
        case EPaidModulesActions.AddUsersPaidModulesSuccess:
            {
                return {
                    ...state,
                    usersPaidModules: []
                }
            }
        case EPaidModulesActions.EditUsersPaidModulesSuccess:
            {
                return {
                    ...state,
                    usersPaidModules: []
                }
            }
        case EPaidModulesActions.RemoveUsersPaidModulesSuccess:
            {
                return {
                    ...state,
                    usersPaidModules: []
                }
            }
        default:
            return state;
    }
}