import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/core/store/state/app.state';
import { selectUsers, selectUsersFilters } from 'app/core/store/selectors/users.selectors';
import { PlannerUser } from 'app/core/models/planner/plannerUser';
import { EditUser, DeleteUser, GetUsers, ExportUsersList, SetUsersFilter } from 'app/core/store/actions/users.actions';
import { IUsersFilter } from 'app/core/models/usersFilter';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as russian } from './i18n/ru';
import { PaidModulesFormDialogComponent } from './paidModules-form/paidModules-form.component';
import { selectUsersPaidModules } from 'app/core/store/selectors/paidModules.selector';
import { GenerateModuleLicenseCommand, PlannerUsersAvailableModules } from '@appmodels';
import { AddUsersPaidModules, EditUsersPaidModules, GenerateLicense, RemoveUsersPaidModules, SetPaidModulesSearchPattern } from 'app/core/store/actions/paidModules.actions';

@Component({
    selector: 'paidModules',
    templateUrl: './paidModules.component.html',
    styleUrls: ['./paidModules.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PaidModulesComponent implements OnInit, OnDestroy {
    dialogRef: any;
    hasSelectedContacts: boolean;
    searchInput: FormControl;

    //usersFilter$: Observable<IUsersFilter>;
    paidModules$: Observable<PlannerUsersAvailableModules[]>;

    private _changeSearchPatternTimer: NodeJS.Timer;

    // Private
    private _unsubscribeAll: Subject<any>;

    users$: Observable<PlannerUser[]>;
    /**
     * Constructor
     *
     * @param {UsersService} _contactsService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _matDialog: MatDialog,
        private _store: Store<IAppState>,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, russian);
        // Set the defaults
        this.searchInput = new FormControl('');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._contactsService.onSelectedContactsChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(selectedContacts => {
        //         this.hasSelectedContacts = selectedContacts.length > 0;
        //     });

        // this.searchInput.valueChanges
        //     .pipe(
        //         takeUntil(this._unsubscribeAll),
        //         debounceTime(300),
        //         distinctUntilChanged()
        //     )
        //     .subscribe(searchText => {
        //         this._contactsService.onSearchTextChanged.next(searchText);
        //     });

        //this.usersFilter$ = this._store.pipe(select(selectUsersFilters));
        this.users$ = this._store.pipe(select(selectUsers));

        this.paidModules$ = this._store.pipe(select(selectUsersPaidModules));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    onSearchQuery(event: any): void {
        const searchQuery: string = event.target.value;

        const dispatchFunc = () => this._store.dispatch(new SetPaidModulesSearchPattern(searchQuery));

        clearTimeout(this._changeSearchPatternTimer);
        this._changeSearchPatternTimer = setTimeout(dispatchFunc, 2000);
    }

    addUsersPaidModule(addUsersPaidModule: PlannerUsersAvailableModules): void {


        if (!addUsersPaidModule)
            return;

        this._store.dispatch(new AddUsersPaidModules(addUsersPaidModule));

    }

    editUsersPaidModule(editUsersPaidModule: PlannerUsersAvailableModules): void {

        if (!editUsersPaidModule)
            return;
            
        this._store.dispatch(new EditUsersPaidModules(editUsersPaidModule));
    }

    deleteUsersPaidModule(deletedUsersPaidModule: PlannerUsersAvailableModules): void {

        if (!deletedUsersPaidModule)
            return;

        this._store.dispatch(new RemoveUsersPaidModules(deletedUsersPaidModule));
    }

    /**
     * New contact
     */
    newUsersPaidModule(): void {

        this.dialogRef = this._matDialog.open(PaidModulesFormDialogComponent, {
            panelClass: 'paidModules-form-dialog',
            data: {
                action: 'new'
            }
        });

        const instance = this.dialogRef.componentInstance;

        this.users$.pipe(takeUntil(this._unsubscribeAll)).subscribe((users) => {
            instance.users = users;
        });


        instance.onSearchQueryChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe((query) => {
            this.usersSearch(query);
        });


        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }

                const actionType: string = response[0];

                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':

                        const newPaidModule: PlannerUsersAvailableModules = response[1];

                        this.addUsersPaidModule(newPaidModule);

                        break;
                    /**
                     * Delete
                     */
                    case 'cancel':

                        //this.deleteContact(user);

                        break;
                }
            });
    }

    usersSearch(searchQuery: string): void {
        this._store.dispatch(new SetUsersFilter({ searchQuery: searchQuery }))
    }

    usersFilterChanged(filter: any): void {


        this._store.dispatch(new SetUsersFilter(filter));

    }

    exportUsers(): void {
        this._store.dispatch(new ExportUsersList());
    }

    generateLicenseHandler(generateLicenseCommand: GenerateModuleLicenseCommand): void {

        this._store.dispatch(new GenerateLicense(generateLicenseCommand));

    }

    /**
     * Toggle the sidebar 
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }
}
